// Import Fuse core library
@import "@fuse/scss/core";

// Import app.theme.scss
@import "app/app.theme";


.sk-flow {
    width: 96px;
    height: 24px;
    display: flex;
    justify-content: space-between;
}

.sk-flow-dot {
    width: 25%;
    height: 100%;
    background-color: #6882bd;
    border-radius: 50%;
    animation: sk-flow 1.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite both;
}

.sk-flow-dot:nth-child(1) { animation-delay: -0.30s; }
.sk-flow-dot:nth-child(2) { animation-delay: -0.15s; }

@keyframes sk-flow {
    0%, 80%, 100% {
        transform: scale(0.3); }
    40% {
        transform: scale(1);
    }
}

.sk-wave-wrapper {
    display: inline-block;
}

.sk-wave {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: space-between;
}

.sk-wave-rect {
    background-color: #6882bd;
    height: 100%;
    width: 12%;
    animation: sk-wave 1.2s infinite ease-in-out;
}

.sk-wave-rect:nth-child(1) { animation-delay: -1.2s; }
.sk-wave-rect:nth-child(2) { animation-delay: -1.1s; }
.sk-wave-rect:nth-child(3) { animation-delay: -1.0s; }
.sk-wave-rect:nth-child(4) { animation-delay: -0.9s; }
.sk-wave-rect:nth-child(5) { animation-delay: -0.8s; }

@keyframes sk-wave {
    0%, 40%, 100% {
        transform: scaleY(0.4);
    } 20% {
          transform: scaleY(1);
      }
}

.sk-chasing-dots .sk-child, .sk-spinner-pulse, .sk-three-bounce .sk-child {
  background-color: mat-color($ad-blue, 300);
  border-radius: 100%
}

.sk-three-bounce {
  margin: 112px auto;
  width: 120px;
  text-align: center
}

.sk-three-bounce .sk-child {
  &:first-child {
    margin-right: 8px;
  }
  &:last-child {
    margin-left: 8px;
  }
  width: 32px;
  height: 32px;
  display: inline-block;
  -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both
}

.sk-circle .sk-child:before, .sk-fading-circle .sk-circle:before {
  display: block;
  border-radius: 100%;
  content: '';
  background-color: #333
}

.sk-three-bounce .sk-bounce1 {
  -webkit-animation-delay: -.32s;
  animation-delay: -.32s
}

.sk-three-bounce .sk-bounce2 {
  -webkit-animation-delay: -.16s;
  animation-delay: -.16s
}

@-webkit-keyframes sk-three-bounce {
  0%, 100%, 80% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes sk-three-bounce {
  0%, 100%, 80% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}
